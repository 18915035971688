<template>
  <div
    id="root"
    class="flickity"
  >
    <slot />
  </div>
</template>

<script>
import Flickity from 'flickity';

export default {
  setup() {
    return {
      ready: false,
    };
  },

  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.initFlickity();
    });
  },

  methods: {
    initFlickity() {
      const elem = document.querySelector('#root');
      console.log('FLICKITY_LOADED', JSON.stringify(this.options, undefined, 2));
      const flkty = new Flickity(elem, {
        ...this.options,
      });
      console.log('FLICKITY_LOADED', flkty);
    },
  },
};
</script>

<style lang="css" scoped>
  @import url('../../node_modules/flickity/dist/flickity.min.css');
</style>
