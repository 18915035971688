<template>
  <div
    class="container"
  >
    <div v-if="initialized">
      <div>
        <h3>
          <span
            @click="goBack"
            class="backbutton"
          >
            <img
              :src="require('@/assets/img/back-button.svg')"
              alt="#"
            >
          </span>
          {{ project.client }}
          <span style="font-weight: 300">
            {{ project[`title_${this.$i18n.locale}`] }}
          </span>
        </h3>
      </div>

      <p>
        {{ project[`description_${this.$i18n.locale}`] }}
        <br>
      </p>
      <flickity
        ref="flickity"
        :options="project.images.length === 1
          ? { ...flickityOptions, 'prevNextButtons': false, 'pageDots': false }
          : flickityOptions"
      >
        <div
          v-for="(imagePath, index) in project.images"
          :key="index"
          class="carousel-cell"
        >
          <img
            :src="`https://storage.googleapis.com/visualspace-nl/${imagePath}`"
          >
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flickity from './Flickity';
import 'flickity-fullscreen';

export default {
  components: {
    Flickity,
  },

  data() {
    return {
      flkty: null,
      initialized: false,
      flickityOptions: {
        fullscreen: true,
        wrapAround: true,
        lazyLoad: 1,
        on: {
          ready: () => {
            console.log('Flickity is ready');
          },
          change: (index) => {
            console.log(`Slide changed to${index}`);
          },
        },
      },
    };
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'projects',
      'categories',
      'project',
    ]),
  },

  methods: {
    setCurrentProject() {
      const project = this.projects.find((p) => p.slug === this.$route.params.slug);
      this.$store.commit('setProject', project);
      this.initialized = true;
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  watch: {
    // This is here to enable reload on detail pages. That is to say
    // when a user comes from a search engine direct to a detail page.
    // So we watch for the projects to load. If this happens we set
    // the project manually.
    projects(newValue, oldValue) {
      if (this.initialized || oldValue !== null) {
        this.setCurrentProject();
      }
    },
  },

  mounted() {
    if (this.projects.length > 0) {
      this.setCurrentProject();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .flickity-button:focus {
  box-shadow: none !important;
}
</style>
