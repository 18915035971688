<template>
  <project-detail-carousel />
</template>

<script>
import ProjectDetailCarousel from '@/components/ProjectDetailCarousel';

export default {
  components: {
    ProjectDetailCarousel,
  },
};
</script>
